import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import { useState, useEffect, useRef } from "react";
import gsap from "gsap";

type DesktopNavProps = {
  desktopLinks: Array<{
    name: any;
    url: string;
    isDesktop: boolean;
  }>;
};

type Timeout = ReturnType<typeof setTimeout>;

const DesktopNav = ({ desktopLinks }: DesktopNavProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hoverTarget, setHoverTarget] = useState<HTMLElement | null>(null);
  const [currentRoute, setCurrentRoute] = useState<string | null>(null);
  const isHoveringTimeout = useRef<Timeout | null>(null);
  const hoverIndicator = useRef<HTMLDivElement>(null);

  function handleMouseEnter() {
    setIsHovering(true);
    if (isHoveringTimeout.current) {
      clearTimeout(isHoveringTimeout.current);
    }
  }

  function handleMouseLeave() {
    isHoveringTimeout.current = setTimeout(() => setIsHovering(false), 500);
  }

  useEffect(() => {
    if (hoverTarget) {
      gsap.to(hoverIndicator.current, {
        width: hoverTarget.offsetWidth - 48,
        x: hoverTarget.offsetLeft + 24,
        duration: 0.35,
        ease: "expo.out(1.7)",
        // ease: "back.out(1.7)",
      });
    }
  }, [hoverTarget]);

  useEffect(() => {
    if (!isHovering) {
      const activeRoute = desktopLinks.find(
        (link) => link.url === currentRoute
      );
      if (activeRoute) {
        const activeRouteRef = document.querySelector(
          `[href="${activeRoute.url}"]`
        );
        if (activeRouteRef) {
          gsap.to(hoverIndicator.current, {
            width: activeRouteRef.offsetWidth,
            x: activeRouteRef.offsetLeft,
            duration: 0.35,
            ease: "back.out(1.7)",
          });
        }
      } else {
        gsap.to(hoverIndicator.current, {
          width: 0,
          duration: 0.35,
          ease: "back.out(1.7)",
        });
      }
    }
  }, [isHovering, currentRoute]);

 const browserWindow = typeof window !== "undefined" ? window : null;
 useEffect(() => {
   setCurrentRoute(window.location.pathname);
 }, [browserWindow?.location?.pathname]);

  

  return (
    <nav className={`hidden lg:block w-full float-right ml-auto pt-12 px-6`}>
      <div className={`table float-right relative`}>
        <ul>
          {desktopLinks.map((link, index) => {
            if (link.isDesktop) {
              const linkRef = useRef<HTMLDivElement>(null);
              return (
                <li key={index} className={`inline-block`}>
                  <div
                    ref={linkRef}
                    onMouseEnter={() => {
                      handleMouseEnter();
                      setHoverTarget(linkRef.current);
                    }}
                    onMouseLeave={handleMouseLeave}
                  >
                    <PrimaryButton
                      url={link.url}
                      className={`mx-6 btn-small`}
                      hover="green"
                      hasUnderline={false}
                    >
                      {link.name}
                    </PrimaryButton>
                  </div>
                </li>
              );
            }
          })}
        </ul>
        <div
          ref={hoverIndicator}
          className={`absolute top-full left-0 block h-0.5 mt-0.5 bg-green`}
        ></div>
      </div>
    </nav>
  );
};

export default DesktopNav;
