import * as React from "react";
import LinkFadeDown from "../TransitionLinks/LinkFadeDown";

// function that checks if string is an absolute url or mailto:
const isExternalLink = (url: string) => {
  if (url.includes("mailto") || url.includes("http")) return true;
  return false;
};

//  function that checks if string is an absolute url or mailto:, or a downloadable zip file
const isExternalOrDownloadLink = (url: string) => {
  if (url.includes("mailto") || url.includes("http") || url.includes(".zip"))
    return true;
  return false;
};

//  function that checks if string is a downloadable zip file
const isDownloadLink = (url: string) => {
  if (url.includes(".zip")) return true;
  return false;
};

// function that takes a url string to a zip file and returns the filename
const getDownloadFileName = (url: string) => {
  const splitUrl = url.split("/");
  const fileName = splitUrl[splitUrl.length - 1];
  return fileName;
};

type PrimaryButtonProps = {
  children: React.ReactNode;
  ref?: any;
  url: string;
  anchor?: string;
  className?: string;
  color?: "green" | "red" | "black" | "white";
  hover?: "green" | "red" | "black" | "white";
  hasUnderline?: boolean;
  beforeNavigating?: () => void;
};

const PrimaryButton = ({
  children,
  url,
  className,
  color = "green",
  hover = "red",
  hasUnderline = true,
  anchor,
  beforeNavigating,
  ref,
}: PrimaryButtonProps) => {
  const textColor = () => {
    switch (color) {
      case "green":
        return "text-green border-green";
      case "red":
        return "text-red-light border-red-light";
      case "black":
        return "text-black border-black";
      case "white":
        return "text-white border-white";
      default:
        return "text-green border-green";
    }
  };

  const hoverColor = () => {
    switch (hover) {
      case "green":
        return "hover:text-green hover:border-green";
      case "red":
        return "hover:text-red-light hover:border-red-light";
      case "black":
        return "hover:text-black hover:border-black";
      case "white":
        return "hover:text-white hover:border-white";
      default:
        return "hover:text-red hover:border-red";
    }
  };

  return (
    // if url contains mailto, .zip, or an external link use a regular anchor tag
    isExternalOrDownloadLink(url) ? (
      <a
        ref={ref}
        href={url}
        download={isDownloadLink(url) ? getDownloadFileName(url) : false}
        target={isExternalLink(url) ? "_blank" : undefined}
        className={`btn-primary font-mdio font-medium text-lg transform duration-300 ${
          hasUnderline ? "border-b-2" : null
        } uppercase py-1.5
		${textColor()}
		${hoverColor()}
		${className}
		`}
      >
        {children}
      </a>
    ) : (
      <LinkFadeDown
        ref={ref}
        url={url}
        anchor={anchor}
        beforeNavigating={beforeNavigating}
        className={`btn-primary font-mdio font-medium text-lg transform duration-300 ${
          hasUnderline ? "border-b-2" : null
        } uppercase py-1.5
      ${textColor()}
      ${hoverColor()}
        ${className}
        `}
      >
        {children}
      </LinkFadeDown>
    )
  );
};

export default PrimaryButton;
