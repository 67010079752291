import React from "react";

type EyeSVGProps = {
  color?: string;
};

const DiscordIcon = ({ color = "#DCEF29" }: EyeSVGProps) => {
  return (
    <svg
      width="27"
      height="21"
      viewBox="0 0 27 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1599 1.69704C20.4661 0.90574 18.6546 0.330252 16.7646 0C16.5325 0.421807 16.2611 0.984216 16.0747 1.43545C14.0638 1.13136 12.0692 1.13136 10.0942 1.43545C9.90783 0.984216 9.6299 0.421807 9.39447 0C7.49797 0.326982 5.68649 0.90574 3.99273 1.70031C0.575764 6.86335 -0.352865 11.8956 0.111449 16.8559C2.37744 18.5464 4.57149 19.5764 6.72957 20.2467C7.26255 19.5143 7.73667 18.7328 8.14867 17.9121C7.37045 17.6145 6.62166 17.2516 5.91538 16.8265C6.10176 16.6892 6.28487 16.542 6.46144 16.3949C10.7645 18.4091 15.4436 18.4091 19.6944 16.3949C19.8742 16.5453 20.0574 16.6892 20.2405 16.8265C19.5342 17.2516 18.7854 17.6178 18.0039 17.9153C18.4126 18.7361 18.8868 19.5176 19.423 20.25C21.5844 19.5764 23.7817 18.5497 26.0444 16.8559C26.5905 11.1076 25.119 6.11783 22.1599 1.69704ZM8.73397 13.8052C7.44239 13.8052 6.38297 12.5986 6.38297 11.1305C6.38297 9.66232 7.4195 8.45249 8.73397 8.45249C10.0484 8.45249 11.1079 9.65905 11.085 11.1305C11.0882 12.5986 10.0484 13.8052 8.73397 13.8052ZM17.4252 13.8052C16.1336 13.8052 15.0742 12.5986 15.0742 11.1305C15.0742 9.66232 16.1107 8.45249 17.4252 8.45249C18.7396 8.45249 19.799 9.65905 19.7762 11.1305C19.7762 12.5986 18.7396 13.8052 17.4252 13.8052Z"
        fill={color}
      />
    </svg>
  );
};

export default DiscordIcon;
