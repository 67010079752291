import React from "react";
import MobileNavButton from "../Buttons/MobileNavButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import GojiraIcon from "../svg/GojiraIcon";
import GojiraLogo from "../Logo/GojiraLogo";
import ANFTIcon from "../svg/ANFTIcon";
import DesktopNav from "./DesktopNav";
import { useState, useEffect } from "react";

type NavProps = {
  lockScroll: (boolean) => void;
};

const Nav = ({ lockScroll }: NavProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHome, setIsHome] = useState(false);

  const toggle = () => {
    lockScroll(!isOpen);
    setIsOpen(!isOpen);
  };

  const mobileLinks = [
    {
      name: "About Gojira",
      url: "/",
      anchor: "#about-gojira",
      isDesktop: false,
    },
    {
      name: "Shop",
      url: "/shop",
      isDesktop: true,
    },
    {
      name: "Games",
      url: "/",
      anchor: "#games",
      isDesktop: false,
    },
    {
      name: (
        <>
          <span className={`inline-block mr-3`}>
            <ANFTIcon />
          </span>
          Animatronic NFTs
        </>
      ),
      url: "/animatronic-nfts",
      isDesktop: true,
    },
    {
      name: "Get in touch",
      url: "mailto:inquiries@gojiralabs.com",
      isDesktop: true,
    },
  ];

  const desktopLinks = [
    {
      name: (
        <>
          <span className={`inline-block mr-3 -mb-0.5`}>
            <ANFTIcon />
          </span>
          Animatronic NFTs
        </>
      ),
      url: "/animatronic-nfts",
      isDesktop: true,
    },
    // {
    //   name: "Games",
    //   url: "/#games",
    //   isDesktop: true,
    // },
    {
      name: "Shop",
      url: "/shop",
      isDesktop: true,
    },
    {
      name: "Get in touch",
      url: "mailto:inquiries@gojiralabs.com",
      isDesktop: true,
    },
  ];

  // variable that stores window if we are in the browser
  const browserWindow = typeof window !== "undefined" ? window : null;
  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
      lockScroll(false);
    }

    setIsHome(checkIsHome());
  }, [browserWindow?.location.pathname]);

  return (
    <div className={`absolute w-screen top-0 left-0 block z-30 lg:flex`}>
      {isHome ? (
        <div className="hidden sm:block mt-11 ml-11">
          <GojiraLogo isRotated />
        </div>
      ) : (
        <div className={`p-6 md:mt-6 md:ml-4`}>
          <GojiraIcon />
        </div>
      )}
      {/* Desktop/Tablet Nav */}
      <DesktopNav desktopLinks={desktopLinks} />

      {/* Mobile Nav Toggle Button */}
      <div className={`absolute top-0 right-0 z-50 lg:hidden`}>
        <MobileNavButton isOpen={isOpen} toggleNav={toggle} />
      </div>
      {/* Close Overlay */}
      <div
        onClick={() => {
          toggle();
        }}
        className={`absolute top-0 left-0 z-40 h-screen w-screen overflow-hidden lg:hidden ${
          isOpen ? "block" : "hidden"
        }`}
      ></div>
      {/* Mobile Nav */}
      <nav
        className={`min-w-[330px] w-4/5 absolute h-screen z-40 bg-black pt-32 pb-10 px-7 overflow-y-scroll transition-all top-0 lg:hidden ${
          isOpen ? "right-0 duration-700" : "-right-full duration-1000"
        }`}
      >
        <ul>
          {mobileLinks.map((link, index) => {
            return (
              <li key={index}>
                <PrimaryButton
                  url={link.url}
                  anchor={link.anchor}
                  beforeNavigating={() => {
                    toggle();
                  }}
                  className={`mb-7 table mx-auto`}
                >
                  {link.name}
                </PrimaryButton>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Nav;

// function that checks if the user is on the home page
const checkIsHome = () => {
  const browserWindow = typeof window !== "undefined" ? window : null;
  return browserWindow?.location.pathname === "/";
};