import React from "react";
import EyeSVG from "../../components/svg/EyeSVG";

// Moble Nav Button
type MobileNavButtonProps = {
  toggleNav: () => void;
  isOpen: boolean;
};

const MobileNavButton = ({ toggleNav, isOpen }: MobileNavButtonProps) => {
  return (
    <button
      className={`p-6`}
      onClick={() => toggleNav()}
      aria-label="Toggle Mobile Navigation"
    >
      <EyeSVG />
      <span className={`sr-only`}>{isOpen ? "Close" : "Open"} menu </span>
    </button>
  );
};

export default MobileNavButton;
