import React from "react";

type EyeSVGProps = {
  color?: string;
  size?: number;
};

const EyeSVG = ({ color = "#FFFFFF", size = 40 }: EyeSVGProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.9543 0 0 8.95431 0 20C0 31.0457 8.9543 40 20 40Z"
        fill={color}
      />
      <path
        d="M19.5 28C24.1944 28 28 24.1944 28 19.5C28 14.8056 24.1944 11 19.5 11C14.8056 11 11 14.8056 11 19.5C11 24.1944 14.8056 28 19.5 28Z"
        fill="black"
      />
    </svg>
  );
};

export default EyeSVG;
