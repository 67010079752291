import React from "react";

type TwitterIconProps = {
  color?: string;
};

const TwitterIcon = ({ color = "#DCEF29" }: TwitterIconProps) => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4502 4.97244C22.4604 5.19255 22.4604 5.40265 22.4604 5.62277C22.4604 12.2961 17.2999 20 7.86266 20C5.07924 20 2.34661 19.2196 0 17.7389C0.406339 17.7889 0.812678 17.8089 1.21902 17.8089C3.52499 17.8089 5.77001 17.0485 7.58838 15.6378C5.39415 15.5978 3.46404 14.1871 2.79358 12.126C3.56562 12.2761 4.35798 12.2461 5.10971 12.036C2.71231 11.5558 0.99553 9.48471 0.99553 7.0835V7.02347C1.70662 7.41367 2.50914 7.63378 3.32182 7.65379C1.06664 6.17304 0.375863 3.21155 1.7371 0.910394C4.33767 4.06198 8.18773 5.98295 12.3121 6.19305C11.8956 4.44217 12.4644 2.60124 13.7952 1.37063C15.8574 -0.540336 19.1081 -0.440286 21.0484 1.59074C22.1963 1.37063 23.2934 0.950414 24.3092 0.360117C23.9232 1.53071 23.1207 2.5212 22.054 3.15152C23.0699 3.03146 24.0654 2.76133 25 2.35112C24.3295 3.37163 23.4559 4.25208 22.4502 4.97244Z"
        fill={color}
      />
    </svg>
  );
};

export default TwitterIcon;
