import React from "react";
import { Helmet } from "react-helmet";

export const meta_title = "Gojira Labs";
const meta_description =
  "Gojira Labs is a player-first gaming studio. We’re obsessed with play, utility and using Web3 gaming as a hotbed for innovation and creativity.";
const meta_image = "https://gojiralabs.com/favicon/meta.jpg";
const meta_url = "https://gojiralabs.com/";

export default function Meta() {
  return (
    // @ts-ignore
    <Helmet>
      <meta charSet="utf-8" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <meta name="msapplication-TileColor" content="#DCEF29" />
      <title>{meta_title}</title>
      <meta property="og:title" content={meta_title} />
      <meta property="og:description" content={meta_description}></meta>
      <meta property="og:url" content={meta_url} />
      <meta property="og:image" content={meta_image} />

      <meta name="twitter:title" content={meta_title} />
      <meta name="twitter:description" content={meta_description} />
      <meta name="twitter:image" content={meta_image} />

      <meta name="description" content={meta_image} />
      <link rel="canonical" href={meta_title} />

      <meta name="theme-color" content="#DCEF29" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
  );
}
