import React from "react";
import Gus from "../svg/Gus";
import DiscordIcon from "../svg/DiscordIcon";
import LinkedInIcon from "../svg/LinkedInIcon";
import TwitterIcon from "../svg/TwitterIcon";
import PrimaryButton from "../Buttons/PrimaryButton";
import BackToTop from "../svg/BackToTop";

// function that returns the current year
const getCurrentYear = () => {
	  return new Date().getFullYear();
};

// objecta variable of social links
const socialLinks = [
  {
    name: "Discord",
    link: "https://discord.gg/SfK4ZSWrNA",
    icon: <DiscordIcon />,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/gojira-labs/",
    icon: <LinkedInIcon />,
  },
  {
    name: "Twitter",
    link: "https://twitter.com/GojiraLabs",
    icon: <TwitterIcon />,
  },
];

const footerLinks = [
  {
    name: "Animatronic NFTs",
    link: "/animatronic-nfts",
  },
  {
    name: "Press kit",
    link: "/GojiraLabs_PressKit_April2023.zip",
  },
  {
    name: "Store",
    link: "/shop",
  },
  {
    name: "Compliance",
    link: "/compliance/privacy-policy",
  },
];

const isOnHomepage = (location) => {
  return location.pathname === "/";
};

const Footer = ({ location }) => {
  return (
    <footer
      id={"footer"}
      className={`w-full  text-white z-40 relative footer ${
        isOnHomepage(location) ? "bg-red-light pt-20 md:pt-24" : null
      }`}
    >
      {isOnHomepage(location) ? (
        <Gus
          className={`mx-auto w-full h-auto max-w-[1600px] -mb-[1px] md:-mb-96`}
        />
      ) : null}

      {/* Mobile Footer */}
      <div className={`bg-black text-white md:hidden border-t border-black`}>
        {isOnHomepage(location) ? <BackToTop className={`md:hidden`} /> : null}

        <div className="w-full pt-28 px-8 pb-4">
          <ul className={`table mb-10`}>
            {socialLinks.map((link, index) => {
              return (
                <li key={index} className={`mr-5 float-left`}>
                  <a target="_blank" rel="noreferrer" href={link.link}>
                    {link.icon}
                    <span className="sr-only">{link.name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
          <ul className={`table mb-4`}>
            {footerLinks.map((link, index) => {
              return (
                <li key={index} className={``}>
                  <PrimaryButton
                    url={link.link}
                    color={"green"}
                    hover={"red"}
                    hasUnderline={false}
                    className={`block mb-2`}
                  >
                    {link.name}
                  </PrimaryButton>
                </li>
              );
            })}
          </ul>
          <PrimaryButton
            url={"mailTo:inquiries@gojiralabs.com"}
            color={"green"}
            hover={"red"}
            className={`table mb-14`}
          >
            inquiries@gojiralabs.com
          </PrimaryButton>
          <p
            className={`font-mdio text-white text-sm uppercase tracking-widest`}
          >
            © GOJIRA LABS 2023
          </p>
        </div>
      </div>

      {/* Desktop Footer */}
      <div className={`bg-black text-white hidden md:block`}>
        <div className="flex w-full pt-48 px-8 pb-10 max-w-[1600px] mx-auto">
          <div className="w-full">
            <ul className={`table mb-4`}>
              {footerLinks.map((link, index) => {
                return (
                  <li key={index} className={``}>
                    <PrimaryButton
                      url={link.link}
                      color={"green"}
                      hover={"red"}
                      hasUnderline={false}
                      className={`block mb-2`}
                    >
                      {link.name}
                    </PrimaryButton>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="w-full flex flex-row items-end">
            <p
              className={`font-mdio w-full text-white text-base uppercase tracking-widest text-center`}
            >
              © GOJIRA LABS {getCurrentYear()}
            </p>
          </div>

          <div className="w-full flex flex-col items-end">
            <ul className={`table mb-10`}>
              {socialLinks.map((link, index) => {
                return (
                  <li
                    key={index}
                    className={`${
                      index + 1 === socialLinks.length ? "" : "mr-5"
                    } float-left`}
                  >
                    <a target="_blank" rel="noreferrer" href={link.link}>
                      {link.icon}
                      <span className="sr-only">{link.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
            <div>
              <PrimaryButton
                url={"mailTo:inquiries@gojiralabs.com"}
                color={"green"}
                hover={"red"}
                className={`table mb-14`}
              >
                inquiries@gojiralabs.com
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
