import React from "react";

type LinkedInIconProps = {
  color?: string;
};

const LinkedInIcon = ({ color = "#DCEF29" }: LinkedInIconProps) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.375 2.17391C4.375 3.3913 3.4125 4.34783 2.1875 4.34783C0.9625 4.34783 0 3.3913 0 2.17391C0 0.956522 0.9625 0 2.1875 0C3.4125 0 4.375 0.956522 4.375 2.17391ZM4.375 6.08696H0V20H4.375V6.08696ZM11.375 6.08696H7V20H11.375V12.6957C11.375 8.6087 16.625 8.26087 16.625 12.6957V20H21V11.2174C21 4.34783 13.2125 4.6087 11.375 8V6.08696Z"
        fill={color}
      />
    </svg>
  );
};

export default LinkedInIcon;
