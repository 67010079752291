import React from "react";
import Meta from "../components/Meta";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import { useState } from "react";
import Helmet from "react-helmet";

export default function Layout({ children, location }) {
  const [isScrollLocked, setIsScrollLocked] = useState(false);

  return (
    <>
      <Helmet>
        <body
          className={`w-screen max-w-screen overflow-x-hidden ${
            isScrollLocked ? "h-screen overflow-hidden" : null
          }`}
        />
      </Helmet>
      <Meta />
      <div
        id="app"
        className="relative block w-screen max-w-screen overflow-x-hidden"
      >
        <Nav lockScroll={setIsScrollLocked} />
        <main className={`relative`}>{children}</main>
        <Footer location={location} />
      </div>
    </>
  );
}
