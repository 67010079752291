exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animatronic-nfts-tsx": () => import("./../../../src/pages/animatronic-nfts.tsx" /* webpackChunkName: "component---src-pages-animatronic-nfts-tsx" */),
  "component---src-pages-compliance-cookie-policy-js": () => import("./../../../src/pages/compliance/cookie-policy.js" /* webpackChunkName: "component---src-pages-compliance-cookie-policy-js" */),
  "component---src-pages-compliance-privacy-policy-js": () => import("./../../../src/pages/compliance/privacy-policy.js" /* webpackChunkName: "component---src-pages-compliance-privacy-policy-js" */),
  "component---src-pages-compliance-terms-and-conditions-js": () => import("./../../../src/pages/compliance/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-compliance-terms-and-conditions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */)
}

