import React, { ReactNode, useCallback } from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import { navigate } from "gatsby";

interface IProps {
  children: ReactNode;
  node?: ReactNode;
  url: string;
  className?: string;
  ref?;
  anchor?: string;
  beforeNavigating?: () => void;
}

const handleAnchorClick = (event, to, anchor, beforeNavigating) => {
  event.preventDefault();

  if (beforeNavigating) beforeNavigating();

  if (anchor && typeof window !== "undefined") {
    // check if current location is the same as to
    if (window.location.pathname === to) {
      // if so, scroll to anchor
      setTimeout(() => {
        const element = document.querySelector(anchor);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      // if not, push to history and scroll to anchor
      window.history.pushState({}, "", `${to}${anchor}`);
      // wait for transition to finish, then scroll to anchor
      setTimeout(() => {
        const element = document.querySelector(anchor);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 2000);
    }
  } else {
    // Use the regular gatsby-plugin-transition-link behavior if no anchor is provided.
    setTimeout(() => {
      navigate(to);
    }, 10);
  }
};

export const LinkFadeDown = ({
  children,
  url,
  anchor,
  className,
  ref,
  beforeNavigating,
}: IProps) => {
  const exitTransition = {
    length: 0.8,
    zIndex: 2,
    trigger: ({ node }: IProps) => {
      exitTransition.exitTrigger(node);
      if (node) (node as HTMLElement).style.top = -window.pageYOffset + "px";
      window.scrollTo({ top: -window.pageYOffset });
    },
    exitTrigger: useCallback((container) => {
      container.setAttribute(
        "style",
        "animation: fadeDownOut 0.8s cubic-bezier(0.83, 0, 0.17, 1) forwards;"
      );
    }, []),
  };

  const entryTransition = {
    zIndex: 1,
    trigger: ({ node }: IProps) => {
      entryTransition.entryTrigger(node);
    },
    entryTrigger: useCallback((container) => {
      container.setAttribute(
        "style",
        "animation: fadeDownIn 1.6s cubic-bezier(0.83, 0, 0.17, 1) forwards;"
      );
    }, []),
  };

  return (
    <>
      <TransitionLink
        ref={ref}
        to={url}
        onClick={(event) =>
          handleAnchorClick(event, url, anchor, beforeNavigating)
        }
        exit={exitTransition}
        entry={entryTransition}
        className={className}
      >
        {children}
      </TransitionLink>
    </>
  );
};

export default LinkFadeDown;
