import React from "react";

type BackToTopProps = {
  color?: string;
  className?: string;
};

const BackToTop = ({ color = "#FFFFFF", className }: BackToTopProps) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <button className={`table p-6 mx-auto ${className}`} onClick={scrollToTop}>
      <svg
        width="141"
        height="19"
        viewBox="0 0 141 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_359_294)">
          <path
            d="M4 9.884H7.178C9.404 9.884 10.524 9.002 10.524 7.224C10.524 6.23 10.16 5.516 9.502 5.236L9.04 5.04V4.662L9.432 4.438C9.964 4.144 10.216 3.57 10.216 2.758C10.216 1.064 9.124 0.224 6.884 0.224H4V9.884ZM5.414 8.638V5.558H7.178C8.494 5.558 9.04 6.006 9.04 7.098C9.04 8.19 8.508 8.638 7.178 8.638H5.414ZM5.414 4.368V1.456H6.912C8.242 1.456 8.774 1.876 8.774 2.912C8.774 3.948 8.228 4.368 6.912 4.368H5.414Z"
            fill={color}
          />
          <path
            d="M13.9485 9.884H15.4185L15.8945 7.826H19.3245L19.8005 9.884H21.3125L18.9745 0.224H16.2865L13.9485 9.884ZM16.1885 6.566L17.3785 1.47H17.8405L19.0305 6.566H16.1885Z"
            fill={color}
          />
          <path
            d="M28.2091 10.108C30.4211 10.108 31.5411 8.848 31.6811 6.496H30.2391C30.1411 8.036 29.4971 8.764 28.2091 8.764C26.6971 8.764 26.0811 7.714 26.0811 5.054C26.0811 2.45 26.7251 1.344 28.2091 1.344C29.4691 1.344 30.1131 2.114 30.2111 3.598H31.6391C31.4851 1.26 30.4211 0 28.2091 0C25.7311 0 24.5971 1.582 24.5971 5.054C24.5971 8.526 25.7311 10.108 28.2091 10.108Z"
            fill={color}
          />
          <path
            d="M40.7336 9.884H42.4276L39.2496 4.858L42.3436 0.224H40.6916L37.9896 4.242H36.9676V0.224H35.5396V9.884H36.9676V5.502H37.9756L40.7336 9.884Z"
            fill={color}
          />
          <path
            d="M58.8947 9.884H60.3227V1.512H63.0667V0.224H56.1507V1.512H58.8947V9.884Z"
            fill={color}
          />
          <path
            d="M70.1032 10.108C72.5392 10.108 73.6732 8.526 73.6732 5.054C73.6732 1.582 72.5392 0 70.1032 0C67.6672 0 66.5332 1.582 66.5332 5.054C66.5332 8.526 67.6672 10.108 70.1032 10.108ZM68.0312 5.054C68.0312 2.45 68.6612 1.344 70.1032 1.344C71.5452 1.344 72.1892 2.45 72.1892 5.054C72.1892 7.714 71.5732 8.764 70.1032 8.764C68.6332 8.764 68.0312 7.714 68.0312 5.054Z"
            fill={color}
          />
          <path
            d="M90.3783 9.884H91.8063V1.512H94.5502V0.224H87.6342V1.512H90.3783V9.884Z"
            fill={color}
          />
          <path
            d="M101.587 10.108C104.023 10.108 105.157 8.526 105.157 5.054C105.157 1.582 104.023 0 101.587 0C99.1508 0 98.0168 1.582 98.0168 5.054C98.0168 8.526 99.1508 10.108 101.587 10.108ZM99.5148 5.054C99.5148 2.45 100.145 1.344 101.587 1.344C103.029 1.344 103.673 2.45 103.673 5.054C103.673 7.714 103.057 8.764 101.587 8.764C100.117 8.764 99.5148 7.714 99.5148 5.054Z"
            fill={color}
          />
          <path
            d="M109.043 9.884H110.457V6.636H112.305C114.517 6.636 115.679 5.698 115.679 3.43C115.679 1.162 114.517 0.224 112.305 0.224H109.043V9.884ZM110.457 5.362V1.498H112.333C113.579 1.498 114.195 2.016 114.195 3.458C114.195 4.886 113.607 5.362 112.333 5.362H110.457Z"
            fill={color}
          />
          <path
            d="M129.626 4.634H131.152L132.846 1.512H133.28L134.96 4.634H136.514L134.106 0.224H132.048L129.626 4.634Z"
            fill={color}
          />
        </g>
        <defs>
          <filter
            id="filter0_d_359_294"
            x="0"
            y="0"
            width="140.514"
            height="18.1079"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_359_294"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_359_294"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </button>
  );
};

export default BackToTop;
